<template>
  <div class="boothListContainer">
    <div class="banner">
      <img
        class="image"
        :src="
          LOCALE == 'en' ? bannerData.banner_url_en : bannerData.banner_url_zh
        "
        :class="{ cursor: bannerData.url }"
        @click="goToUrl"
        alt
      />
    </div>
    <div class="searchHead">
      <searchHead
        :pavilion_id="pavilion_id"
        @search="search"
        :type="1"
      ></searchHead>
    </div>
    <div class="content_area paddingB70">
      <div class="contentBox" v-loading="loading">
        <noDataImg v-if="dataList.length == 0 && !loading" />
        <div class="list">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <al-image
              class="itemImage"
              :src="item.logo ? item.logo : defaultLogo"
              fit="scale-down"
            >
            </al-image>
            <div class="itemContent">
              <div
                class="companyName textOverflow"
                :title="
                  item.company_name_en
                    | priorFormat(item.company_name_zh, LOCALE)
                "
              >
                {{
                  item.company_name_en
                    | priorFormat(item.company_name_zh, LOCALE)
                }}
              </div>
              <div class="ardessAndOnline">
                <div class="ardess textOverflow">
                  {{
                    item.company_country_id
                      | countryCityFormat(
                        item.company_region_en,
                        item.company_region_zh,
                        LOCALE
                      )
                  }}
                </div>
                <div class="statusBox tr">
                  <div class="onlie" v-if="item.online_status == 1">
                    <i style="background: #5bda22"></i>
                    <span>{{ $t("status_Online") }}</span>
                  </div>
                  <div class="onlie" v-if="item.online_status == 2">
                    <i style="background: #ff5155"></i>
                    <span>{{ $t("status_Busy") }}</span>
                  </div>
                  <div class="onlie" v-if="item.online_status == 3">
                    <i style="background: #c0c4cc"></i
                    ><span>{{ $t("status_Offline") }}</span>
                  </div>
                </div>
              </div>

              <div class="businessListNoMore">
                <div class="businessItemList">
                  <span
                    v-for="(it, ind) in item.business_classification"
                    :key="'a' + ind"
                    class="businessItem"
                  >
                    <span v-if="ind < 2"
                      >#{{ it | bussinessFormat(LOCALE) }}</span
                    >
                  </span>
                </div>
                <div
                  class="more pointer"
                  @click="goDetail(item, true)"
                  v-if="item.business_classification.length > 2"
                >
                  ...{{ $t("more") }}
                </div>
              </div>
              <div class="btnBox">
                <span class="pointer" @click="goDetail(item)">{{
                  $t("meetingJoinBooth")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer clearfix layout">
        <el-pagination
          v-if="!loading"
          class="fr"
          background
          layout="prev, pager, next"
          :page-size="limit"
          :total="total"
          :hide-on-single-page="true"
          :current-page.sync="currentPage"
          @current-change="currentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import searchHead from "~exp/components/booth/searchHead";

export default {
  name: "boothList",
  components: { searchHead },
  data() {
    return {
      dataList: [],
      defaultLogo: "",
      loading: false,
      currentPage: 1,
      limit: 20,
      total: 0,
      bannerData: {
        banner_url_en: "",
        banner_url_zh: "",
        url: "",
      },
      bannerLoading: false,
      pavilion_id: null, //展馆id
      params: {
        country_id: "",
        city_id: "",
        company_name: "",
        certificate: "",
        industry: "",
        business_classification: "",
        company_id: "",
        lanes: "",
        booth_no: "",
      },
    };
  },
  created() {
    this.pavilion_id = this.$route.query.pavilion_id;
    this.$nextTick(() => {
      this.getBannerList();
    });
    this.defaultLogo =
      this.LOCALE == "en"
        ? "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverEn.jpg"
        : "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/boothDefaultCoverZh.jpg";
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    search(value) {
      this.currentPage = 1;
      this.params.country_id = value.country;
      this.params.city_id = value.city;
      this.params.company_name = value.company;
      this.params.certificate = value.association;
      this.params.industry = value.industry;
      this.params.business_classification = value.business_classification;
      this.params.lanes = value.major;
      this.params.booth_no = value.boothId;

      this.getDataList();
    },
    async getDataList() {
      this.loading = true;
      let params = Object.assign({}, this.params);
      params.meeting_id = this.MEETING_ID;
      params.pavilion_id = this.pavilion_id;
      params.start = this.currentPage - 1;
      params.limit = this.limit;
      let res = await this.$store.dispatch("booth/getBoothList", params);
      this.loading = false;
      if (res.success) {
        this.dataList = res.data;
        this.total = res.count;
      }
    },
    async getBannerList() {
      this.bannerLoading = true;
      let params = {
        meeting_id: this.MEETING_ID,
        pavilion_id: this.pavilion_id,
      };
      let res = await this.$store.dispatch("booth/getPavilionBanner", params);
      this.bannerLoading = false;
      if (res.success) {
        let data = res.data.find((item) => {
          return item.id == this.pavilion_id;
        });
        this.bannerData = data;
      }
    },
    goToUrl() {
      if (this.bannerData.url) {
        window.open(this.bannerData.url);
      }
    },
    currentChange(index) {
      this.currentPage = index;
      this.BACKPAGETOP();
      this.getDataList();
    },
    goDetail(item, more = false) {
      if (more) {
        this.$log({
          type: 2,
          id: "Hall.business.more",
          topic: "Hall.business",
        });
      } else {
        this.$log({
          type: 2,
          id: "Hall.business.enter",
          topic: "Hall.business",
          content: JSON.stringify({
            boothId: item.booth_id,
          }),
        });
      }

      const { href } = this.$router.resolve({
        path: "/boothDetail",
        query: {
          company_id: item.company_id,
          booth_id: item.booth_id,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.boothListContainer {
  background: #f6f7f9;
}

.banner {
  position: relative;
  padding-top: 18.2%;
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    max-height: 600px;
  }
}

.searchHead {
  padding: 24px 0;
}

.contentBox {
  min-height: 300px;
  position: relative;
}

.list {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 310px;
    margin-right: 20px;
    margin-bottom: 24px;
    height: auto;
    position: relative;

    .itemImage {
      width: 310px;
      height: 186px;
      display: block;
      border: 1px solid #e9e9e9;
      border-bottom: none;
    }

    .itemContent {
      border: 1px solid #e9e9e9;
      border-top: none;
      background: #ffffff;
    }

    .companyName {
      padding: 6px 16px 0;
      font-weight: bold;
      color: #4c4c4c;
      line-height: 20px;
      width: 278px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ardessAndOnline {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
      width: 100%;

      .ardess {
        max-width: 220px;
        color: #717171;
      }

      .onLine {
        font-size: 12px;
        color: #717171;
        display: flex;
        align-items: center;

        .onLine1 {
          width: 6px;
          height: 6px;
          background: #5bda22;
          margin-right: 6px;
          border-radius: 50%;
        }
      }
    }

    .businessListMore {
      margin-top: 14px;
      padding: 0 16px;
      color: #1ea89d;
      font-size: 12px;
      height: auto;
      display: flex;
      flex-wrap: wrap;

      .businessItem {
        margin-right: 6px;
        margin-bottom: 2px;
      }

      .businessItem:last-child {
        margin-right: 0;
      }
    }

    .businessListNoMore {
      display: flex;
      justify-content: space-between;
      margin-top: 14px;
      padding: 0 16px;
      color: #1ea89d;
      font-size: 12px;

      .businessItemList {
        width: 240px;
        height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #1ea89d;
        font-size: 12px;
        .businessItem {
          margin-right: 6px;
        }

        .businessItem:last-child {
          margin-right: 0;
        }
      }

      .more {
        color: #909399;
        font-size: 12px;
      }
    }

    .btnBox {
      display: flex;
      background: #ffffff;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #e9e9e9;
      color: #3b9af0;
      font-weight: bold;
      margin-top: 17px;
      height: 48px;
    }
  }

  .item:nth-child(4n) {
    margin-right: 0;
  }
}
 .statusBox {
    width: 80px;
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 4px;
      i {
        width: 6px;
        height: 6px;
        border-radius: 3px;
      }
      span {
        padding-left: 6px;
      }
    }
  }
.pointer {
  cursor: pointer;
}

.layout {
  width: 1300px;
  margin: 0 auto;
}

.paddingB70 {
  padding-bottom: 70px;
}
</style>
